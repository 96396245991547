//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "Contact",

  data() {
    return {
      apiTitle: this.$api.api_title + 'images/',
      topPicture: {},
      concat_list: [],
      linguisticType: localStorage.getItem('linguisticType')
    };
  },

  created() {
    this.getImg();
    this.getList();
  },

  methods: {
    getImg() {
      this.$http.get(this.$api.listTopPicture, {
        params: {
          pictureType: 6
        }
      }).then(res => {
        if (res.code == 0) {
          if (res.data.rows.length > 0) {
            this.topPicture = res.data.rows[0];
          }
        }
      });
    },

    // 获取介绍
    getList() {
      this.$http.get(this.$api.listContactUs, {
        params: {
          linguisticType: localStorage.getItem('linguisticType'),
          pageNum: 1,
          pageSize: 10000
        }
      }).then(res => {
        if (res.code == 0) {
          this.concat_list = res.data.rows;
        }
      });
    }

  }
};