var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "top-img-box" }, [
      _c("img", {
        staticClass: "top-img",
        attrs: { src: _vm.apiTitle + _vm.topPicture.pictureAddress, alt: "" }
      })
    ]),
    _c("div", { staticClass: "subtitle" }, [
      _vm.linguisticType == 0
        ? _c("span", { staticClass: "span-subtitle" }, [_vm._v("联系我们")])
        : _vm._e(),
      _vm.linguisticType == 1
        ? _c("span", { staticClass: "span-subtitle" }, [_vm._v("Contact Us")])
        : _vm._e()
    ]),
    _c("div", { staticClass: "concatContent" }, [
      _c("div", { staticClass: "concat" }, [
        _c(
          "div",
          _vm._l(_vm.concat_list, function(v, i) {
            return _c("div", { key: i, staticClass: "first" }, [
              _c("div", { staticClass: "word" }, [
                _c("h3", [_vm._v(_vm._s(v.headLine))]),
                _c("p", { staticClass: "little-word" }, [
                  _vm._v(_vm._s(v.emailDescription))
                ]),
                _c("div", { staticClass: "note" }, [
                  _c("p", { staticClass: "email" }, [_vm._v(_vm._s(v.mailbox))])
                ])
              ]),
              _c("div", { staticClass: "imgbox" }, [
                _c("img", {
                  staticClass: "weixin",
                  attrs: { src: _vm.apiTitle + v.pictureAddress }
                }),
                _c("h4", { staticClass: "profile" }, [
                  _vm._v(_vm._s(v.pictureDescribe))
                ])
              ])
            ])
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }